































































































































































































































































































































import _ from 'lodash';
import Vue from 'vue';
import moment from 'moment';
import mime from 'mime-types';
import { mapGetters } from 'vuex';
import { DataTable, SurveyForm } from '../components/admin';
import {
  UtilsMixin,
  ReportMixin,
  SurveyFormMixin,
  SurveyMixin,
  NotificationMixin,
} from '../mixins';
import { Component, Mixins } from 'vue-property-decorator';
import { BModal } from 'bootstrap-vue';

@Component({
  name: 'Dashboard',
  components: {
    DataTable,
    SurveyForm,
  },
  computed: {
    ...mapGetters('survey', {
      getSurveyList: 'getList',
      getSurveyCount: 'getCount',
    }),
    ...mapGetters('report', {
      getReportList: 'getList',
      getReportCount: 'getCount',
    }),
    ...mapGetters('auth', ['currentUser']),
  },
})
export default class Dashboard extends Mixins(
  UtilsMixin,
  ReportMixin,
  SurveyMixin,
  NotificationMixin,
  SurveyFormMixin
) {
  selected: any = null;
  summary: any = {};
  surveyFields = [
    {
      key: 'teamName',
      sortable: true,
      thClass: 'width-95',
    },
    {
      key: 'template.name',
      sortKey: 'name',
      sortable: true,
      label: 'Survey Type',
      thClass: 'survey-type',
    },
    {
      key: 'status',
      sortable: true,
      thClass: 'width-135',
    },
    {
      key: 'createdAt',
      label: 'Created',
      sortable: true,
      formatter(val) {
        return val ? moment(val).format(process.env.VUE_APP_DATE_FORMAT) : null;
      },
    },
    {
      key: 'startDate',
      sortable: true,
      thClass: 'width-85',
      formatter(val) {
        return val ? moment(val).format(process.env.VUE_APP_DATE_FORMAT) : null;
      },
    },
    {
      key: 'endDate',
      sortable: true,
      formatter(val) {
        return moment(val).format(process.env.VUE_APP_DATE_FORMAT);
      },
    },
    {
      key: 'participantLimit',
      label: 'Participants',
      sortable: true,
      thClass: 'width-100',
    },
    {
      key: 'totalParticipants',
      label: 'Completions',
      sortable: true,
      thClass: 'width-100',
    },
    {
      key: 'percentComplete',
      label: 'Complete',
      sortable: true,
      thClass: 'width-95',
      formatter(val) {
        return `${val}%`;
      },
    },
    {
      key: 'daysLeft',
      sortable: true,
      formatter(val) {
        return _.isInteger(val) ? val : '-';
      },
    },
    {
      key: 'averageTime',
      label: 'Avg Time',
      sortable: true,
      formatter(val) {
        const minutes = (val / 60)
          .toFixed(1)
          .replace('0.0', '')
          .replace('.0', '');
        return parseInt(minutes) ? `${minutes} mins` : '-';
        // return val ? moment.duration(val, 'seconds').humanize() : '-'
      },
    },
  ];

  reportFields = [
    {
      key: 'teamName',
      sortable: true,
      thClass: 'width-95',
    },
    {
      key: 'template.name',
      sortKey: 'name',
      sortable: true,
      label: 'Report Type',
      thClass: 'report-type',
    },
    {
      key: 'createdAt',
      label: 'Created',
      sortable: true,
      formatter(val) {
        return val ? moment(val).format(process.env.VUE_APP_DATE_FORMAT) : null;
      },
    },
  ];

  completionFields = [
    {
      key: 'fullName',
      label: 'Name',
      sortable: true,
    },
    {
      key: 'createdAt',
      label: 'Submitted',
      formatter(val) {
        return val
          ? moment(val).format(process.env.VUE_APP_DATETIME_FORMAT)
          : null;
      },
      sortable: true,
    },
  ];

  fetchSurveyData(params: Record<string, string | number> = {}) {
    params.sort = params.sort || 'endDate';
    params.clients = this.$route.params.client;
    this.$store.dispatch('survey/list', { params });
    this.$store.dispatch('survey/count', { params });

    const params2 = {
      clients: this.$route.params.client,
    };
    this.$store
      .dispatch('survey/getSummary', { params: params2 })
      .then((res) => {
        this.summary = res;
        // try {
        // const averageTime = res.totalTime / res.totalParticipants
        // const minutes = (averageTime / 60).toFixed(1)
        // // this.summary.averageTime = moment.duration(averageTime, 'seconds').humanize()
        // this.summary.averageTime = `${minutes} minutes`.replace('.0', '')
        // } catch(err) {
        //     console.log(err)
        // }
        for (const surveyName in this.summary.surveyStats) {
          const averageTime =
            this.summary.surveyStats[surveyName].totalTime /
            this.summary.surveyStats[surveyName].totalParticipants;
          const minutes = averageTime
            ? (averageTime / 60).toFixed(1).replace('0.0', '').replace('.0', '')
            : null;
          // this.summary.averageTime = moment.duration(averageTime, 'seconds').humanize()
          this.summary.surveyStats[surveyName].averageTime =
            minutes && parseInt(minutes) ? `${minutes} minutes` : '-';
        }
      });
  }

  fetchReportData(params: Record<string, string | number> = {}) {
    params.sort = params.sort || 'name';
    params.clients = this.$route.params.client;
    params.pdf = 1;
    this.$store.dispatch('report/list', { params });
    this.$store.dispatch('report/count', { params });
  }

  copyEmailTemplate(survey) {
    const link = `${process.env.VUE_APP_SURVEY_BASEURL}/${survey.client.slug}/${survey.slug}`;
    const endDate = moment(survey.endDate).format(
      process.env.VUE_APP_DATE_FORMAT
    );
    const otherEmailTemplate = `
This email template is for you to edit.  It is written as if being sent from the Team Leader.  Please amend accordingly if sending directly to the team. [Please delete this text before sending!]

Dear ${survey.teamName},

As part of our programme of activity focused on team alignment and leadership, we are running a short survey over the next two weeks where I’d ask each of you to complete an assessment of our effectiveness as a team. This survey will form a baseline assessment of the team and highlight areas of strength and weakness.  

Please take time to complete the survey (which only takes about 10 minutes) and be as honest as possible. The views you express in the survey should be your personal view of how we work together as a team, and will be completely anonymous.

The survey is available now and will be open until ${endDate} to allow you time to complete it. The link to the survey is as follows:

${link}

If you have any queries at all, please do not hesitate to contact [insert appropriate administrator contact details].

Many thanks in advance for your input.`;

    const externalPerceptionEmailTemplate = `
This email template is for you to edit.  It is written as if being sent from the Team Leader.  Please amend accordingly if sending directly to the team. [Please delete this text before sending!]

Dear Colleagues,

As part of our programme of activity focused on our team alignment and leadership, we are running a quick survey over the next two weeks where I’d ask each of you to complete an assessment of the ${survey.teamName}’s effectiveness.

Please take the time to complete the survey (approximately 10 mins) and be as honest as possible as it will act as useful input to the team’s development and as valuable feedback. The views you express in the survey should be your personal view of how we work together as a team, and will be completely anonymous.

The survey is available now and will be open until ${endDate} to allow you time to complete it. The link to the survey is as follows:

${link}

If you have any queries at all, please do not hesitate to contact [insert appropriate administrator contact details].

Many thanks in advance for your input.`;

    const chcEmailTemplate = `
The sample email below is provided as a starting point and is written as if being sent from the leader sponsoring the campaign. It should be adapted to your organisation’s unique situation and to capture the sender’s own style and voice. [Please delete this text before sending!]

Dear [INSERT EMPLOYEE NAME]

Over the coming weeks, ${survey.client.name} will be conducting a survey in order to get a better understanding of the cultural ‘health’ of the business and what may be getting in the way from a behavioural perspective. Only you can tell us what the real experience is of working here, so your opinions are important to us. This survey is your chance to express those opinions.

The survey is currently live and will be open until ${endDate}. It consists of 40 'finish the sentence' style questions, taking no longer than 15 minutes to complete.

The link to the survey is as follows: ${link}

All responses will remain strictly anonymous and we will not be able to attribute responses to any individual. Please be thoughtful, honest, and candid when you complete the survey as this will guide us in identifying the most important areas for us to focus on. In order to ensure that each and every employee's opinion is heard and to make best use of the tool, we would like to get as full a participation as possible.

If you have any queries at all, please do not hesitate to contact [INSERT APPROPRIATE ADMINISTRATOR CONTACT DETAILS].

Many thanks, in advance, for your input.`;
    let emailTemplate;
    if (survey.name.indexOf('External') > -1) {
      emailTemplate = externalPerceptionEmailTemplate;
    } else if (survey.name.indexOf('Culture') > -1) {
      emailTemplate = chcEmailTemplate;
    } else {
      emailTemplate = otherEmailTemplate;
    }
    this.copyToClipboard(emailTemplate).then(() => {
      this.notifySuccess('Copied to clipboard');
    });
  }

  editSurvey(survey) {
    if (survey.status !== 'completed') {
      this.selected = Object.assign({}, survey);
      this.$refs.editModal.show();
    }
  }

  onCancel() {
    this.selected = null;
    this.$refs.editModal.hide();
  }

  surveyDescription(surveyName) {
    // TODO: move this to db
    if (
      surveyName === 'Team Effectiveness' ||
      surveyName === 'Team Effectiveness 1'
    ) {
      return 'A diagnostic analysis of a team’s strengths and weaknesses, combining the team’s own self-assessment with an externally perceived view.';
    } else if (surveyName === 'Team Effectiveness 2') {
      return 'A diagnostic analysis of a team’s strengths and weaknesses, combining the team’s own self-assessment with an externally perceived view, assessed at two points over time.';
    } else if (
      surveyName === 'Team Effectiveness - Self Assessment' ||
      surveyName === 'Team Effectiveness - Self Assessment 1'
    ) {
      return 'A diagnostic analysis based on a team’s self-assessment of its operations and behaviours.';
    } else if (surveyName === 'Team Effectiveness - Self Assessment 2') {
      return 'A comparative diagnostic analysis based on a team’s self-assessment of its operations and behaviours, assessed at two points over time.';
    } else if (surveyName === 'Team Effectiveness - Self Assessment 3') {
      return 'A comparative diagnostic analysis based on a team’s self-assessment of its operations and behaviours, assessed at three points over time.';
    } else if (
      surveyName === 'Team Effectiveness - External Perception' ||
      surveyName === 'Team Effectiveness - External Perception 1'
    ) {
      return 'A diagnostic analysis based on an external view of a team’s strengths and weaknesses, as assessed by their direct reports, peers and management.';
    } else if (surveyName === 'Team Effectiveness - External Perception 2') {
      return 'A comparative diagnostic based on an external view of a team’s strengths and weaknesses, as assessed by their direct reports, peers and management at two points over time.';
    } else if (
      surveyName === 'Culture Health Check' ||
      surveyName === 'Culture Health Check 1'
    ) {
      return 'Focuses on assessing an organisation’s cultural ‘health’, diagnosing the presence and extent to which a range of ‘viruses’ exist within the business.';
    } else if (surveyName === 'Culture Health Check 2') {
      return 'A comparative diagnostic focusing on assessing an organisation’s cultural ‘health’, diagnosing the presence and extent to which a range of ‘viruses’ exist within the business at two points over time.';
    }
  }
}
